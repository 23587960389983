/* eslint-disable no-undef,no-console, camelcase */
import { toast } from 'react-toastify';

import { http } from '../../http';
import { getRewardPoints } from '../index';
import * as actionTypes from '../actionTypes';
import {
  EditEventAPI,
  FeaturedEvents,
  CreateEvent,
  GetPlaces,
  GetEvents,
  GetEventDetailsById,
  GetCommentsOnEvent,
  AddCommentOnEvent,
  AddCommentReplyOnEvent,
  AttendEvent,
  GetEventAttendees,
  UpdateEventPoints,
  SearchUserForEvent,
  GetActiveEvents,
  GetPastEvents,
  GetOndemandEventDetailsById,
  CreateOnDemandFitnessVideo,
  EditOnDemandVideo,
  OnDemandCategories,
  GetOnDemandVideosByCatgoryId,
  DeleteEventById,
  DeleteOnDemandEventByVideoId,
  GetCompanyEvents,
  GetWolibaEvents,
  GetFriendListToInviteForEvents,
  InviteUserForJoiningEvents,
  GetUserListForEvents
} from '../../constants/apiConstants';
import {getLiveEvents} from '../userProfileActions/index';
import { EventLimitPerPage } from '../../../utils/constants';

const networkError = (error) => (dispatch) => dispatch({type: actionTypes.NETWORK_ERROR, networkError: error});

const createEvent = (eventData) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.EVENT_LOADING});
    const config = {
      headers: {
        AUTHTOKEN: AuthToken
      }
    };
    try {
      const res = await http.post(CreateEvent, eventData, config);
      if(res.message) {
        toast.error(res.message);

        // failedFunc ? failedFunc () : null
        dispatch({type: actionTypes.FAILED_CREATE_EVENT, createEventError: res.message});

      } else {
        toast.success(res[0]);
        dispatch({type: actionTypes.SUCCESSFULLY_CREATED_EVENT, eventCreated: res[0]});
        window.location.replace('/events');
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};


const createOndemand = (eventData) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const config = {
      headers: {
        AUTHTOKEN: AuthToken
      }
    };
    try {
      const res = await http.post(CreateOnDemandFitnessVideo, eventData, config);
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_CREATE_ONDEMAND_EVENT, createOndemandEventError: res.message});
        // callback();
      } else {
        toast.success(res[0]);
        dispatch({type: actionTypes.SUCCESSFULLY_CREATED_ONDEMAND_EVENT, eventCreatedOndemand: res[0]});
        window.location.replace('/events');
        // callback();
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};


const getPlacesAction = (string) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const config = {
      headers: {
        AUTHTOKEN: AuthToken
      }
    };
    try {
      const res = await http.get(`${GetPlaces}/${string}`, config);
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_GET_PLACES, placesError: res.message});
      } else {
        dispatch({type: actionTypes.GET_PLACES, places: res[0] && res[0].predictions});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getEvents = (PageNumber) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.EVENT_LOADING});
    try {
      const res = await http.get(`${GetEvents}/${PageNumber}/${EventLimitPerPage}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_GET_EVENTS, eventsError: res.message});
      } else {
        dispatch({type: actionTypes.GET_EVENTS, events: res.response, totalCount: res.count, PageNumber});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getActiveEvents = (PageNumber) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.EVENT_LOADING});
    try {
      const res = await http.get(`${GetActiveEvents}/${PageNumber}/${EventLimitPerPage}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_GET_ACTIVE_EVENTS, activeEventsError: res.message});
      } else {
        dispatch({type: actionTypes.GET_ACTIVE_EVENTS, activeEvents: res.response, PageNumber});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getPastEvents = (PageNumber) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.EVENT_LOADING});
    try {
      const res = await http.get(`${GetPastEvents}/${PageNumber}/${EventLimitPerPage}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_GET_PAST_EVENTS, pastEventsError: res.message});
      } else {
        dispatch({type: actionTypes.GET_PAST_EVENTS, pastEvents: res.response, PageNumber});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      // window.location.reload();
    }
  }
};

const setAttendingStatus = (isAttending) => (dispatch) => dispatch({type: actionTypes.SET_ATTENDING_STATUS, isAttending});

const getAttendingStatus = () => (dispatch) => dispatch({type: actionTypes.GET_ATTENDING_STATUS});

const getEventDetailsById = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.EVENT_LOADING});
    try {
      const res = await http.get(`${GetEventDetailsById}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_GET_EVENT_DETAILS, eventDetailError: res.message});
        if(window.location.pathname.includes('company')){
          window.location.replace('/company/dashboard');
        }else{
          window.location.replace('/events');
        }
      } else {       
        dispatch({type: actionTypes.GET_EVENT_DETAILS, eventDetail: res[0]});
        dispatch(setAttendingStatus(res[0].is_attending));
        dispatch(getAttendingStatus());
      }
    } catch(error) {
      dispatch(networkError(error.message));
      if(window.location.pathname.includes('company')){
        window.location.replace('/company/dashboard');
      }else{
        window.location.replace('/events');
      }
      toast.error(error.message);
    }
  }
};

const getCommentsOnEvent = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.EVENT_LOADING});
    try {
      const res = await http.get(`${GetCommentsOnEvent}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch({type: actionTypes.FAILED_GET_COMMENTS_ON_EVENT, commentError: res.message});
      } else {
        dispatch({type: actionTypes.GET_COMMENTS_ON_EVENT, comments: res});
      }
    } catch(error) {
      dispatch(networkError(error.message));
      toast.error(error.message);
      window.location.reload();
    }
  }
};

const addCommentOnEvent = (commentData) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const config = {
      headers: {
        AUTHTOKEN: AuthToken
      }
    };
    try {
      const res = await http.post(AddCommentOnEvent, commentData, config);
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_ADD_COMMENT_ON_EVENT, postCommentError: res.message});
      } else {
        dispatch({type: actionTypes.ADD_COMMENT_ON_EVENT, commentSuccess: res[0]});
        dispatch(getCommentsOnEvent(commentData.event_id));
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const addCommentReplyOnEvent = (commentId, reply, eventId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const data = {
      comment_id: commentId,
      reply: reply
    };
    try {
      const res = await http.post(AddCommentReplyOnEvent, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_ADD_COMMENT_REPLY_ON_EVENT, replyError: res.message});
      } else {
        dispatch({type: actionTypes.ADD_COMMENT_REPLY_ON_EVENT, replySuccess: res[0]});
        dispatch(getCommentsOnEvent(eventId));
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

// const updateEvent = (updationList, isAttending, eventId) => (dispatch) =>
//   dispatch({type: actionTypes.UPDATE_EVENT, updationList, isAttending, id: eventId});

const attendEvent = (eventId, isAttending, addcalendar) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const data = {
      "event_id": eventId,
      "is_attending": isAttending === 1 ? 0 : 1,
      "add_to_calendar":addcalendar
    };
    try {
      const res = await http.put(AttendEvent, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res) {
        if (res.message) {
          typeof res.message === 'string' ?toast.error(res.message) : null ;
        } else {
          if (isAttending !== 1) {
            dispatch(getRewardPoints());
          }
          dispatch(getEventAttendees(eventId));
          dispatch(getEventDetailsById(eventId));
          dispatch(fetchCompanyEvents());
          dispatch(fetchWolibaEvents());
        }
      }
    } catch(error) {
      toast.error(error.message);
    }
  }
};

const attendLunchAndLearnEvent = (eventId, isAttending,isAttended) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const data = {
      "event_id": eventId,
      "is_attending": isAttending,
      "is_attended":isAttended
    };
    try {
      const res = await http.put(AttendEvent, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res) {
        if (res.message) {
          typeof res.message === 'string' ?toast.error(res.message) : null ;
        } else {
          if (isAttending !== 1) {
            dispatch(getRewardPoints());
          }
          dispatch(getEventAttendees(eventId));
          dispatch(getEventDetailsById(eventId));
        }
      }
    } catch(error) {
      toast.error(error.message);
    }
  }
};

const attendEventV2 = (eventId, isAttending, addcalendar) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const data = {
      "event_id": eventId,
      "is_attending": isAttending === 1 ? 0 : 1,
      "add_to_calendar":addcalendar
    };
    try {
      const res = await http.put(AttendEvent, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res) {
        if (res.message) {
          typeof res.message === 'string' ?toast.error(res.message) : null ;
          dispatch({type: actionTypes.FAILED_ATTEND_EVENT, attendError: res.message});
        } else {
          if (isAttending !== 1) {
            dispatch(getRewardPoints());
          }
          // typeof res[0] === 'string' ?toast.success(res[0]) : null ;
          dispatch({type: actionTypes.ATTEND_EVENT, attendSuccess: res[0]});
          dispatch(getEventAttendees(eventId));
          dispatch(fetchCompanyEvents());
          dispatch(fetchWolibaEvents());
        }
      }
    } catch(error) {
      toast.error(error.message);
    }
  }
};

const attendEventV3 = (eventId, isAttending, pageNumber, addcalendar) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const data = {
      "event_id": eventId,
      "is_attending": isAttending === 1 ? 0 : 1,
      "add_to_calendar":addcalendar
    };
    try {
      const res = await http.put(AttendEvent, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res) {
        if (res.message) {
          typeof res.message === 'string' ?toast.error(res.message) : null ;
        } else {
          if (isAttending !== 1) {
            dispatch(getRewardPoints());
          }
          dispatch(getEventAttendees(eventId));
          dispatch(getActiveEvents(pageNumber));
          dispatch(fetchCompanyEvents());
          dispatch(fetchWolibaEvents());
        }
      }
    } catch(error) {
      toast.error(error.message);
    }
  }
};


const attendEventV4 = (eventId, isAttending) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const data = {
      "event_id": eventId,
      "is_attending": isAttending === 1 ? 0 : 1
    };
    try {
      const res = await http.put(AttendEvent, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res) {
        if (res.message) {
          typeof res.message === 'string' ?toast.error(res.message) : null ;
        } else {
          if (isAttending !== 1) {
            dispatch(getRewardPoints());
          }
          dispatch(getEventAttendees(eventId));
          dispatch(getEventDetailsById(eventId));
          dispatch(fetchCompanyEvents());
          dispatch(fetchWolibaEvents());
          dispatch(getLiveEvents());
        }
      }
    } catch(error) {
      toast.error(error.message);
    }
  }
};

const getEventAttendees = (eventId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetEventAttendees}/${eventId}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_GET_EVENT_ATTENDEES, attendeesError: res.message});
      } else {
        dispatch({type: actionTypes.GET_EVENT_ATTENDEES, attendees: res[0]});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      // window.location.reload();
    }
  }
};

const getFeaturedEventsAPI = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(FeaturedEvents, {headers: { AUTHTOKEN: AuthToken }});
      if(res) {
        dispatch({ type: actionTypes.SET_FEATURED_EVENT, data: res})
      }
    }
    catch(error) {
      toast.error(error.message);
    }
  }
};

const getFeaturedEvents = () => (
  (dispatch) => {
    dispatch({ type: actionTypes.GET_FEATURED_EVENT }) 
  }
);

const EditEventAction = (eventData) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async () => {
    try {
      const res = await http.put(EditEventAPI, eventData, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      }
      else {
        toast.success(res[0]);
        window.location.replace(`/events/${eventData.id}`)
      }
    }
    catch(error) {
      toast.error(error.message);
    }
  }
};

const EditOndemandEventVideo = (eventData, category) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async () => {
    try {
      const res = await http.put(EditOnDemandVideo, eventData, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      }
      else {
        toast.success(res[0]);
        window.location.replace(`/events/on-demand/${category}/${eventData.id}`)
      }
    }
    catch(error) {
      toast.error(error.message);
    }
  }
};

const UpdateAttendeesList = (data) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.put(UpdateEventPoints,data,config);
      if(res.message) {
        toast.error(res.message);
      } else{
        toast.success(res[0]);
        dispatch(getEventAttendees(data['event_id']));
        dispatch(SearchAttendeesForEvent({"event_id": data['event_id'], "name": ""}));
        dispatch(getEventDetailsById(data['event_id']));
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const UpdateAttendeesListV2 = (data, eventId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.put(UpdateEventPoints,data,config);
      if(res.message) {
        toast.error(res.message);
      } else{
        toast.success(res[0]);
        dispatch(getEventAttendees(data['event_id']));
        dispatch(SearchAttendeesForEvent({"event_id": data['event_id'], "name": ""}));
        dispatch(getEventAttendees(eventId));
        dispatch(getEventDetailsById(data['event_id']));
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const SearchAttendeesForEvent = (data) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try{
      const res = await http.post(SearchUserForEvent, data, config);
      if(res.message){
        toast.error(res.message);
      } else{
        dispatch({type: actionTypes.GET_SEARCHED_ATTENDEES_LIST, searchedAttendees: res[0]});
      }
    } catch(error){
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
}

const getOndemandEventDetailsById = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.EVENT_LOADING});
    try {
      const res = await http.get(`${GetOndemandEventDetailsById}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_ONDEMAND_EVENT_DETAILS, ondemandEventDetailError: res.message});
      } else {       
        dispatch({type: actionTypes.ONDEMAND_EVENT_DETAILS, ondemandEventDetail: res[0]});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getOndemandCategory = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(OnDemandCategories, {headers: { AUTHTOKEN: AuthToken }});
      if(res) {
        dispatch({type: actionTypes.ONDEMAND_CATEGORY, ondemandCategory: res});
      }
    } catch(error) {
      toast.error(error.message);
    }
  }
};

const fetchOnDemandVideosByCatgoryId = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetOnDemandVideosByCatgoryId}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res) {
        dispatch({type: actionTypes.ONDEMAND_CATEGORY_BY_ID, ondemandByIdData: res});
      }
    } catch(error) {
      toast.error(error.message);
    }
  }
};

const deleteOnDemandEvent = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.post(`${DeleteOnDemandEventByVideoId}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      }
      else {
        toast.success(res[0]);
        dispatch({type: actionTypes.DELETE_ONDEMAND, deleteOndemand: res});
        window.location.replace(`/events`)
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
}

const deleteEventById = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.delete(`${DeleteEventById}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        // toast.error(res.message);
      }
      else {
        toast.success(res[0]);
        dispatch({type: actionTypes.DELETE_EVENT, deleteEvent: res});
        window.location.replace(`/events`)
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
}

const fetchCompanyEvents = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.EVENT_LOADING});
    try {
      const res = await http.get(GetCompanyEvents, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {       
        dispatch({type: actionTypes.GET_COMPANY_EVENTS, companyEvents: res.response});
      }
    } catch(error) {
      toast.error(error.message);
    }
  }
};

const fetchWolibaEvents = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.EVENT_LOADING});
    try {
      const res = await http.get(GetWolibaEvents, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {       
        dispatch({type: actionTypes.GET_WOLIBA_EVENTS, wolibaEvents: res.response});
      }
    } catch(error) {
      toast.error(error.message);
    }
  }
};

const fetchUserEventInvitation = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.EVENT_LOADING});
    try {
      const res = await http.get(`${GetFriendListToInviteForEvents}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {       
        dispatch({type: actionTypes.GET_USER_EVENT_INVITATION, inviteEventUsers: res});
      }
    } catch(error) {
      toast.error(error.message);
    }
  }
};

const inviteUserForJoiningEvents = (data) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async () => {
    const config = {
      headers: {
        AUTHTOKEN: AuthToken
      }
    };
    try {
      const res = await http.post(InviteUserForJoiningEvents, data, config);
      if(res.message) {
        toast.error(res.message);
      } else {
        toast.success(res[0]);
      }
    } catch(error) {
      toast.error(error.message);
    }
  }
};

const fetchUserListForEvents = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.EVENT_LOADING});
    try {
      const res = await http.get(`${GetUserListForEvents}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {       
        dispatch({type: actionTypes.GET_USER_LIST_EVENTS, eventUserList: res[0]});
      }
    } catch(error) {
      toast.error(error.message);
    }
  }
};

const attendEventV5 = (eventId, isAttending, addcalendar, callBack) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const data = {
      "event_id": eventId,
      "is_attending": isAttending === 1 ? 0 : 1,
      "add_to_calendar":addcalendar
    };
    try {
      const res = await http.put(AttendEvent, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res) {
        if (res.message) {
          typeof res.message === 'string' ?toast.error(res.message) : null ;
          dispatch({type: actionTypes.FAILED_ATTEND_EVENT, attendError: res.message});
        } else {
          if (isAttending !== 1) {
            dispatch(getRewardPoints());
          }
          dispatch({type: actionTypes.ATTEND_EVENT, attendSuccess: res[0]});
          callBack();
        }
      }
    } catch(error) {
      toast.error(error.message);
    }
  }
};

const attendEventV6 = (eventId, isAttending, callBack) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const data = {
      "event_id": eventId,
      "is_attending": isAttending === 1 ? 0 : 1,
      "add_to_calendar":null
    };
    try {
      const res = await http.put(AttendEvent, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res) {
        if (res.message) {
          typeof res.message === 'string' ?toast.error(res.message) : null ;
          dispatch({type: actionTypes.FAILED_ATTEND_EVENT, attendError: res.message});
        } else {
          if (isAttending !== 1) {
            dispatch(getRewardPoints());
          }
          dispatch({type: actionTypes.ATTEND_EVENT, attendSuccess: res[0]});
          callBack();
        }
      }
    } catch(error) {
      toast.error(error.message);
    }
  }
};

const attendEventLimitUser = (eventId, isAttending, callback) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const data = {
      "event_id": eventId,
      "is_attending": isAttending === 1 ? 0 : 1
    };
    try {
      const res = await http.put(AttendEvent, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res) {
        if (res.message) {
          typeof res.message === 'string' ?toast.error(res.message) : null ;
          callback();
        } else {
          if (isAttending !== 1) {
            dispatch(getRewardPoints());
          }
          dispatch(getEventAttendees(eventId));
          dispatch(getEventDetailsById(eventId));
          dispatch(fetchCompanyEvents());
          dispatch(fetchWolibaEvents());
        }
      }
    } catch(error) {
      toast.error(error.message);
    }
  }
};

export { EditEventAction, getFeaturedEventsAPI, getFeaturedEvents, createEvent, getPlacesAction, getEvents, getActiveEvents, getPastEvents, getEventDetailsById, getCommentsOnEvent, addCommentOnEvent, addCommentReplyOnEvent,
  attendEvent, getEventAttendees, getAttendingStatus, setAttendingStatus, UpdateAttendeesList, SearchAttendeesForEvent, getOndemandEventDetailsById, createOndemand, EditOndemandEventVideo, getOndemandCategory, fetchOnDemandVideosByCatgoryId, deleteOnDemandEvent, deleteEventById, fetchCompanyEvents, fetchWolibaEvents, attendEventV2, fetchUserEventInvitation, inviteUserForJoiningEvents, attendEventV3, fetchUserListForEvents, UpdateAttendeesListV2, attendEventV4, attendLunchAndLearnEvent, attendEventV5, attendEventV6, attendEventLimitUser};
